<template>
  <w-modal
    :show="show"
    :mask="mask"
    :z-index="zIndex"
    class="w-drawer"
    :class="{
      'is-page': type === 'page',
    }"
    @close="handleClose">
    <div
      class="w-drawer-inner"
      :class="{'is-active': active, [wrapClass]: wrapClass }"
      :style="wrapStyle">
      <template v-if="$slots.header">
        <slot name="header"></slot>
      </template>
      <div v-else-if="!hideHeader" class="w-drawer-header"
        :class="{'w-drawer-header--shadow':!scrollState.start}">
        <div class="w-drawer-title font18b">
          <template v-if="$slots.title">
            <slot name="title"></slot>
          </template>
          <template v-else>{{ title }}</template>
        </div>
      </div>
      <w-button
        v-if="closeBtn"
        class="w-drawer-close"
        icon name="icon_close"
        size="m" :icon-size="24"
        @click="handleClose()" />
      <div v-if="$slots.action" class="w-drawer-action">
        <slot name="action"></slot>
      </div>

      <div ref="scrollbar" class="font15r w-drawer-content" @scroll="updateScroll">
        <div v-if="content" class="w-drawer-content-inner"
          :class="{'font16b no-title': hideHeader}">
          {{ content }}</div>
        <slot v-else></slot>
      </div>
      <div v-if="withBtn" class="w-drawer-footer"
        :class="{
          [`w-drawer-footer--${actionsDirection}`]: actionsDirection,
          [`w-drawer-footer--shadow`]: !scrollState.end,
        }">
        <slot name="footer"></slot>
        <w-button
          v-if="cancelBtn"
          class="font16r"
          v-bind="cancelBtnProps"
          @click="handleCancel">{{ formatCancelText }}
        </w-button>
        <w-button
          v-if="confirmBtn"
          class="font16b"
          v-bind="confirmBtnProps"
          @click="handleConfirm">{{ formatConfirmText }}
        </w-button>
      </div>
    </div>
  </w-modal>
</template>

<script>
import { scrollState } from '@/utils/dom';

export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: '',
      validator(value) {
        return ['page', ''].includes(value);
      },
    },
    title: String,
    content: String,
    hideHeader: Boolean,
    zIndex: Number,
    mask: {
      type: Boolean,
      default: true,
    },
    wrapClass: String,
    wrapStyle: [String, Object],
    actionsDirection: {
      type: String,
      default: 'row',
      validator(val) {
        return ['row', 'column'].includes(val);
      },
    },
    closeBtn: {
      type: Object,
      default() {
        return { type: 'normal-3' };
      },
    },
    confirmBtn: {
      type: [Boolean, Object],
      default() { return null; },
    },
    cancelBtn: {
      type: [Boolean, Object],
      default() { return null; },
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scrollState: { start: true, end: true },
      active: this.show,
    };
  },
  computed: {
    formatCancelText() {
      return this.cancelText || this.$t('global.cancel');
    },
    formatConfirmText() {
      return this.confirmText || this.$t('global.confirm');
    },
    withBtn() {
      return this.confirmBtn || this.cancelBtn || this.$slots.footer;
    },
    confirmBtnProps() {
      if (!this.confirmBtn) return {};
      if (typeof this.confirmBtn === 'object') return this.confirmBtn;
      return { type: 'primary' };
    },
    cancelBtnProps() {
      if (!this.cancelBtn) return {};
      if (typeof this.cancelBtn === 'object') return this.cancelBtn;
      return { plain: true };
    },
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.active = true;
          this.updateScroll();
        });
      } else {
        this.active = false;
      }
    },
  },
  methods: {
    handleClose(e) {
      this.$emit('close', e);
    },
    updateScroll() {
      const { scrollbar: $scrollbar } = this.$refs;
      if (!$scrollbar) return;
      const state = scrollState($scrollbar).y;
      this.scrollState = {
        start: state.start,
        end: state.end,
      };
    },
    async handleConfirm() {
      if (!this.$parent) {
        const { submit } = this.$slots.default[0].componentInstance;
        let resp;
        if (submit) {
          this.loading = true;
          try {
            resp = await submit();
          } catch (err) {
            this.loading = false;
            return;
          }
          this.loading = false;
        }
        this.$emit('confirm', resp);
        return;
      }
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
