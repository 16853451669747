<template>
  <div v-if="solid" class="w-solid-spinner"
    :class="[`w-solid-spinner--${size}`, `w-solid-spinner--${theme}`]">
    <i class="w-icon" :class="iconCss"></i>
    <label v-if="text" :class="fontSize">{{ text }}</label>
  </div>
  <div
    v-else
    class="w-spinner"
    :class="[`w-spinner--${size}`, `w-spinner--${theme}`]">
    <div class="w-spinner1 w-spinner-child"></div>
    <div class="w-spinner2 w-spinner-child"></div>
    <div class="w-spinner3 w-spinner-child"></div>
    <div class="w-spinner4 w-spinner-child"></div>
    <div class="w-spinner5 w-spinner-child"></div>
    <div class="w-spinner6 w-spinner-child"></div>
    <div class="w-spinner7 w-spinner-child"></div>
    <div class="w-spinner8 w-spinner-child"></div>
    <div class="w-spinner9 w-spinner-child"></div>
    <div class="w-spinner10 w-spinner-child"></div>
    <div class="w-spinner11 w-spinner-child"></div>
    <div class="w-spinner12 w-spinner-child"></div>
  </div>
</template>

<script>
export default {
  props: {
    solid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['s', 'm', 'l', 'xl'].indexOf(value) !== -1;
      },
    },
    theme: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'primary', 'danger', 'secondary',
          'normal-2', 'normal-3', 'normal-4', 'white', 'gray', 'yellow', 'red'].indexOf(value) !== -1;
      },
    },
    text: String,
  },
  computed: {
    iconCss() {
      const { theme = '' } = this;
      if (['white', 'yellow', 'gray'].includes(theme)) {
        return `icon-loading--${theme}`;
      }
      return 'icon-loading--red';
    },
    fontSize() {
      const { size = '' } = this;
      if (size === 'l') {
        return 'font14r';
      }
      return 'font12r';
    },
  },
};
</script>
