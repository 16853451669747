var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-modal',{staticClass:"w-dialog__wrapper",class:{[`w-dialog--${_vm.size}`]: _vm.size},attrs:{"show":_vm.show,"close-on-click-modal":false},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"w-dialog__outer",style:({
      maxHeight: `${_vm.maxHeight}px`,
      minHeight: _vm.minHeight,
    })},[_c('div',{staticClass:"w-dialog"},[(_vm.closeBtn)?_c('w-button',_vm._b({staticClass:"w-dialog__close",attrs:{"flat":"","icon":"","name":"icon_close","icon-size":20},on:{"click":_vm.handleClose}},'w-button',_vm.closeBtnConf,false)):_vm._e(),(_vm.title || _vm.$slots.headerSlot)?_c('div',{staticClass:"w-dialog__header",class:{'w-dialog__header--shadow': !_vm.scrollState.start}},[(_vm.titleIcon)?_c('div',{staticClass:"w-dialog__header-icon"},[_c('w-icon',{attrs:{"name":_vm.titleIcon,"size":24}})],1):_vm._e(),_c('div',{staticClass:"w-dialog__header-title",class:_vm.sizeStyle.titleClassName},[_vm._v(_vm._s(_vm.title))]),_vm._t("headerSlot")],2):_vm._e(),_c('w-scrollbar',{ref:"scrollbar",staticClass:"w-dialog__content-wrap",on:{"update":_vm.updateScroll,"scroll":_vm.updateScroll}},[_c('div',{staticClass:"font15r w-dialog__content"},[(_vm.content)?_c('div',{staticClass:"w-dialog__content-inner",class:{
              'font16b no-title': !_vm.title && !_vm.$slots.headerSlot,
              'no-footer': !_vm.withBtn,
            },domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._t("default")],2)]),(_vm.withBtn)?_c('div',{staticClass:"w-dialog__actions",class:{
        [`w-dialog__actions--${_vm.actionsDirection}`]: _vm.actionsDirection,
        [_vm.sizeStyle.actionsClassName]: _vm.sizeStyle.actionsClassName,
        'w-dialog__actions--shadow': !_vm.scrollState.end,
      }},[_vm._l((_vm.buttons),function(button,index){return _c('div',{key:index,staticClass:"w-dialog__actions-item"},[_c('w-button',_vm._b({class:[_vm.sizeStyle.buttonCancelClassName, button.className],attrs:{"loading":_vm.buttonLoading},on:{"click":function($event){return _vm.handleExtraBtnClick(button)}}},'w-button',button.config,false),[_vm._v(_vm._s(button.text)+" ")])],1)}),(_vm.cancelBtn)?_c('div',{staticClass:"w-dialog__actions-item"},[_c('w-button',_vm._b({class:_vm.sizeStyle.buttonCancelClassName,attrs:{"loading":_vm.buttonLoading},on:{"click":_vm.handleCancel}},'w-button',_vm.cancelBtnConf,false),[_vm._v(_vm._s(_vm.formatCancelText)+" ")])],1):_vm._e(),(_vm.confirmBtn)?_c('div',{staticClass:"w-dialog__actions-item"},[_c('w-button',_vm._b({class:_vm.sizeStyle.buttonConfirmClassName,attrs:{"loading":_vm.buttonLoading},on:{"click":_vm.handleConfirm}},'w-button',_vm.confirmBtnConf,false),[_vm._v(_vm._s(_vm.formatConfirmText)+" ")])],1):_vm._e()],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }