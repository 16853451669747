<template>
  <div
    ref="reference"
    :class="[
      'w-select',
      `w-select--${size}`,
      {
        'is-multiple': multiple,
        'is-searchable': searchable,
        'is-disabled': selectDisabled,
        'is-reating': selectReating,
        'w-select--selected': isSelected,
        'w-select--active': active,
      },
    ]"
    tabindex="0">
    <div
      class="w-select__wrap"
      :class="{'is-placeholder': !isSelected}"
      @click="handleToggleActive">
      <div class="w-select__inner">
        <span v-if="!isSelected" class="w-select__placeholder">{{ placeholder }}</span>
        <template v-else-if="selected">
          <template v-if="$scopedSlots.default">
            <slot :selected="selected"></slot>
          </template>
          <template v-else-if="multiple">
            <SelectTag
              v-for="item in selected.slice(0, multipleCollapseCount)"
              :key="item.key"
              :label="item.label"
              closable
              @close="handleSelect(item)" />
            <SelectTag
              v-if="selected.length > multipleCollapseCount"
              :label="`+${selected.length - multipleCollapseCount}`" />
          </template>
          <template v-else>{{ selected.label }}</template>
        </template>
      </div>
      <w-button
        v-if="!selectDisabled && clearable && isSelected"
        class="w-select__clear"
        type="gf3" icon name="icon_home_clearfilter"
        :icon-size="14" size="xs"
        @click.stop="handleClear"
        @mousedown.native.prevent />
      <w-icon class="w-select__icon"
        name="icon_arrow_down" :size="16" />
    </div>
    <SelectDropdown
      ref="dropdown"
      class="w-select-dropdown is-mobile"
      :class="[
        multiple ? 'is-multiple' : '',
        searchable ? 'is-searchable' : '',
        type ? `w-select-dropdown--${type}` : '',
      ]"
      :show="active"
      :title="title"
      :wrap-style="searchable ? 'height: 95%' : ''"
      @close="handleCloseDropdown">
      <div class="w-select-dropdown__header"
        :class="{'w-select-dropdown__header--shadow': !scrollState.start}">
        <div v-if="searchable" class="w-select-dropdown__search">
          <w-input
            ref="searchInput"
            class="font15r-min w-select-dropdown__search-input"
            :value="searchVal"
            clearable
            @input="handleSearchValChange"
            @compositionupdate="handleSearchValChange" />
        </div>
        <div v-if="multiple" class="w-select-dropdown__header-tags">
          <SelectTag
            v-for="item in currentValue.slice(0, multipleCollapseCount)"
            :key="item.key"
            :label="item.label"
            closable
            @close="handleSelect(item)" />
          <SelectTag
            v-if="currentValue.length > multipleCollapseCount"
            :label="`+${currentValue.length - multipleCollapseCount}`" />
        </div>
      </div>
      <w-scrollbar
        ref="scrollbar"
        :auto-height="!searchable"
        class="w-select-dropdown__scrollbar"
        wrap-class="w-select-dropdown__wrap"
        @update="updateScroll" @scroll="updateScroll"
        @touchmove.native="handleTouchMove">
        <template v-if="searchable && searchVal && !displayOptions.length">
          <div class="w-select-dropdown__empty">
            <slot name="empty">
              <i class="w-select-dropdown__empty-icon"></i>
              <span class="font15r-min">{{ $t('lootBar.no_results') }}</span>
            </slot>
          </div>
        </template>
        <template v-else>
          <template v-for="(option, index) in displayOptions">
            <div
              v-if="option.key !== undefined && multiple"
              :key="option.key"
              class="w-select-dropdown__item"
              :class="{'is-selected': currentValue.includes(option)}"
              @click="handleSelect(option)">
              <slot
                v-if="$scopedSlots.option"
                name="option"
                :option="option"
                :selected="currentValue.includes(option)">
              </slot>
              <template v-else>
                <w-checkbox
                  class="w-select-dropdown__item-checkbox"
                  :value="currentValue.includes(option)"
                  icon-type="selectboxpng"
                  img="png" />
                <span class="w-select-dropdown__item-label">
                  {{ option.label }}</span>
              </template>
            </div>
            <div
              v-else-if="option.key !== undefined"
              :key="option.key"
              class="w-select-dropdown__item"
              :class="{'is-selected': selected && option.key === selected.key}"
              @click="handleSelect(option)">
              <slot
                v-if="$scopedSlots.option"
                name="option"
                :option="option"
                :selected="selected && option.key === selected.key"></slot>
              <template v-else>{{ option.label }}</template>
              <w-icon v-if="selected && option.key === selected.key"
                name="icon_done" :size="20" />
            </div>
            <div
              v-else-if="option.label"
              :key="option.label"
              class="w-select-dropdown__label">
              <slot v-if="$scopedSlots.label" name="label" :option="option"></slot>
              <template v-else>{{ option.label }}</template>
            </div>
            <hr v-else :key="`hr-${index}`" class="w-select-dropdown__hr" />
          </template>
        </template>
      </w-scrollbar>
      <div v-if="multiple" class="w-select-dropdown__confirm"
        :class="{'w-select-dropdown__confirm--shadow': !scrollState.end}">
        <w-button type="primary" size="l"
          class="font16b w-select-dropdown__confirm-btn" @click="handleConfirm">
          {{ $t('global.confirm') }}
        </w-button>
      </div>
    </SelectDropdown>
  </div>
</template>

<script>
import { scrollState } from '@/utils/dom';

import SelectDropdown from './select-dropdown.vue';
import SelectTag from '../../select/src/tag.vue';

export default {
  components: { SelectDropdown, SelectTag },
  props: {
    value: [String, Number, Array],
    title: String,
    size: {
      type: String,
      default: 'm',
    },
    clearable: Boolean,
    disabled: Boolean,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    type: String,
    searchable: Boolean,
    multiple: Boolean,
    multipleCollapseCount: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      active: false,
      changed: false,
      currentValue: [],
      scrollState: { start: true, end: true },
      searchVal: '',
    };
  },
  computed: {
    isSelected() {
      return this.multiple ? this.selected.length > 0 : !!this.selected;
    },
    displayOptions() {
      if (!this.searchable || !this.searchVal) {
        return this.options;
      }
      return this.options.filter((option) => {
        const label = option.label.toLowerCase();
        const searchKey = this.searchVal.toLowerCase();
        return label.indexOf(searchKey) !== -1;
      });
    },
    selected: {
      get() {
        if (this.multiple) {
          if (!this.value) return [];
          return this.value.map((option) => {
            const matched = this.options.find((item) => item.key === option);
            return matched;
          });
        }

        if (this.value === undefined || this.value === '' || this.value === null) return null;
        const match = this.options.find((option) => option.key === this.value);
        return match;
      },
      set(val) {
        if (this.multiple) {
          this.$emit('input', val.map((item) => item.key));
        } else {
          this.$emit('input', val?.key, val);
        }
        if (!this.active) {
          this.$emit('blur');
        }
      },
    },
    selectDisabled() {
      return this.disabled;
    },
    selectReating() {
      return this.reating;
    },
  },
  watch: {
    selectDisabled(val) {
      if (val) this.active = false;
    },
    active(val) {
      if (val) {
        this.changed = false;
        this.updateCurrentValue();
        this.$emit('focus');
      } else {
        this.searchVal = '';
        this.$emit('blur');
      }
    },
  },
  created() {
    this.updateCurrentValue();
  },
  methods: {
    updateCurrentValue() {
      if (this.multiple) {
        this.currentValue = this.value ? this.value.map((item) => {
          const match = this.options.find((option) => option.key === item);
          return match;
        }) : [];
      } else {
        this.currentValue = this.value;
      }
    },
    handleCloseDropdown() {
      if (this.multiple && this.changed) {
        this.$dialog({
          isNew: true,
          needTitle: false,
          content: this.$t('global.save_modify'),
          confirmText: this.$t('global.save'),
          cancelText: this.$t('global.not_save'),
          closeBtn: null,
          onConfirm: () => {
            this.handleConfirm();
          },
          onCancel: () => {
            this.handleCloseActive();
          },
        });
      } else {
        this.handleCloseActive();
      }
    },
    handleCloseActive() {
      this.active = false;
    },
    handleToggleActive() {
      if (this.selectDisabled) return;
      if (this.active) {
        this.handleCloseActive();
      } else {
        this.active = true;
      }
    },
    handleSelect(option) {
      if (option.disabled) return;
      this.changed = true;
      if (this.multiple) {
        const key = this.active ? 'currentValue' : 'selected';
        let selected = this[key] ? [...this[key]] : [];
        if (selected.some((item) => item.key === option.key)) {
          selected = selected.filter((item) => item.key !== option.key);
        } else {
          selected.unshift(option);
        }
        this[key] = selected;
      } else {
        this.selected = option;
        this.handleCloseActive();
      }
    },
    updateScroll() {
      const { scrollbar: { wrap: $scrollbar } } = this.$refs;
      const state = scrollState($scrollbar).y;
      this.scrollState = {
        start: state.start,
        end: state.end,
      };
    },
    handleTouchMove() {
      const { searchInput } = this.$refs;
      if (searchInput) searchInput.blur();
    },
    handleSearchValChange(val) {
      this.searchVal = val;
    },
    handleConfirm() {
      const val = this.currentValue.map((item) => item.key);
      this.$emit('input', val);
      this.handleCloseActive();
    },
    handleClear() {
      if (this.multiple) {
        this.selected = [];
      } else {
        this.selected = null;
      }
    },
  },
};
</script>
