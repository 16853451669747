<template>
  <w-modal
    class="w-dialog__wrapper"
    :show="show"
    :close-on-click-modal="false"
    @close="handleClose">
    <div class="w-dialog__outer" :style="mergedWrapStyle">
      <w-button v-if="countdownTime === 0 && needCloseIcon"
        class="w-dialog__close"
        flat icon name="icon_close" type="bg1"
        :icon-size="24"
        @click="handleClose" />
      <div
        class="w-dialog"
        :class="{
          'w-dialog--without-btn': !withBtn,
          'w-dialog--without-title': !title,
          'w-dialog--maximized': maximized,
          'is-customized': customized,
          [wrapClass]: wrapClass,
          [(theme ? 'w-dialog--' + theme : '')]: theme,
        }">
        <div
          v-if="title || $slots.headerSlot"
          class="w-dialog__header">
          <h1>{{ title }}</h1>
          <slot name="headerSlot"></slot>
        </div>

        <w-scrollbar class="w-dialog__content-wrap">
          <div class="w-dialog__content"><slot></slot></div>
          <div v-if="tips" class="w-dialog__tips">{{ tips }}</div>
        </w-scrollbar>

        <div v-if="withBtn" class="w-dialog__actions" :class="{
          [(theme ? 'w-dialog__actions--' + theme : '')]: theme,
        }">
          <w-button
            v-if="needCancelBtn"
            style="width: auto;"
            :loading="loading"
            :disabled="countdownTime > 0"
            plain
            @click="handleCancel">{{ formatCancelText }}
            <template v-if="countdownTime > 0">({{ countdownTime }})</template>
          </w-button>
          <w-button
            v-if="needConfirmBtn"
            style="width: auto;"
            :type="confirmType"
            :loading="loading"
            :disabled="disabled || countdownTime > 0"
            @click="handleConfirm">{{ formatConfirmText }}
            <template v-if="countdownTime > 0">({{ countdownTime }})</template>
          </w-button>
        </div>
      </div>
    </div>
  </w-modal>
</template>

<script>
import { isObject } from '../../utils';

export default {
  props: {
    title: String,
    tips: String,
    show: Boolean,
    maximized: Boolean,
    wrapClass: String,
    theme: String,
    wrapStyle: {
      type: [String, Object],
      default() { return {}; },
    },
    customized: Boolean,
    width: Number,
    maxHeight: Number,
    needCloseIcon: {
      type: Boolean,
      default: true,
    },
    needConfirmBtn: {
      type: Boolean,
      default: true,
    },
    needCancelBtn: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmType: {
      type: String,
      default: 'primary',
    },
    loading: Boolean,
    disabled: Boolean,
    countdown: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      countdownTime: 0,
    };
  },
  computed: {
    formatCancelText() {
      return this.cancelText || this.$t('global.cancel');
    },
    formatConfirmText() {
      return this.confirmText || this.$t('global.confirm');
    },
    withBtn() {
      return !this.customized && (this.needConfirmBtn || this.needCancelBtn);
    },
    mergedWrapStyle() {
      if (isObject(this.wrapStyle)) {
        return {
          ...this.wrapStyle,
          width: `${this.width}px`,
          maxHeight: `${this.maxHeight}px`,
        };
      }
      return `${this.wrapStyle};width:${this.width}px;max-height:${this.maxHeight}px;`;
    },
  },
  watch: {
    show(val) {
      if (val && this.countdown > 0) {
        this.countdownTime = this.countdown;
        const time = window.setInterval(() => {
          this.countdownTime -= 1;
          if (this.countdownTime === 0) {
            window.clearInterval(time);
          }
        }, 1 * 1e3);
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async handleConfirm() {
      if (!this.$parent) {
        const { submit } = this.$slots.default[0].componentInstance;
        let resp;
        if (submit) {
          this.loading = true;
          try {
            resp = await submit();
          } catch (err) {
            this.loading = false;
            return;
          }
          this.loading = false;
        }
        this.$emit('confirm', resp);
        return;
      }
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="less" scoped>
.w-dialog {
  border: 1px solid @tint_dark_4;
  &__close {
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px;
  }
  &__header h1 {
    .font24;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &--without-title {
    padding-top: 24px;
  }
  &__content {
    padding: 0 16px;
    font-size: 18px;
    line-height: 30px;
    color: @tint_light_2;
  }
  &__actions {
    padding: 16px;
    &>* {
      flex: 1 1 0;
      margin-right: 16px;
      height: 48px;
      font-size: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__tips {
    .font14;
    color: @label_primary_3;
    background-color: fade(@tint_primary_3, 6%);
    padding: 12px 16px;
    white-space: pre-wrap;
  }
  &--without-btn {
    padding-bottom: 24px;
  }
  &--maximized {
    height: 100%;
    width: 100%;
    min-width: 704px;
    max-width: 1024px;
    min-height: 400px;
    max-height: 900px;
    display: flex;
    flex-direction: column;

    .w-dialog {

      &__header,
      &__actions {
        flex: 0 0 auto;
      }

      &__header {
        padding: 16px 32px 0;
      }

      &__actions {
        padding: 20px 32px 32px;
      }

      &__content {
        flex: 1 1 0;
        overflow: hidden;
        padding: 0 32px;
      }
    }
  }

  &.is-customized {
    padding: 0;
    .w-dialog {
      &__header {
        padding: 0;
        font-weight: bold;
        background: @tint_dark_2;
        text-align: center;
        height: 40px;
        h1 {
          font-size: 20px;
          line-height: 40px;
          color: @tint_light_2;
        }
      }
      &__content {
        padding: 0;
      }
    }
  }

  // 迷你皮肤： mini
  &--mini {
    .w-dialog__header {
      text-align: center;
    }
    .p-dialog-content {
      min-height: inherit;
      text-align: center;
    }
  }
  // 按钮并排皮肤： columnBtn
  &--columnBtn {
    .w-dialog__actions {
      flex-direction: column-reverse;
      & > * {
        flex: 0 0 auto;
        margin: 0;
        margin-top: 16px;
        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .w-dialog__wrapper {
    padding: 0 16px;
  }
  .w-dialog__outer {
    padding: 72px 0;
  }
  .w-dialog {
    width: 100%;
  }
  .w-dialog__close {
    top: auto;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .w-dialog__header {
    padding: 20px 60px 16px 20px;
  }
  .w-dialog__header h1 {
    .font18;
  }
  .w-dialog--mini {
    .w-dialog__header {
      color: var(--el-gf1);
      h1 {
        .font16;
        line-height: 20px;
        .fontBold();
      }
    }
  }
  .w-dialog__actions:not(.w-dialog__actions--mini,.w-dialog__actions--columnBtn) {
    flex-direction: column-reverse;
    & > * {
      flex: 0 0 auto;
      margin: 0;
      margin-top: 16px;
      &:last-child {
        margin-top: 0;
      }
    }
  }
}
</style>
