import { generateUrl as generateUrl2 } from './utils';

export default {
  postImRecallMsg() {
    return generateUrl2('/api/im/recall_msg', false);
  },
  postImAckMsgRead() {
    return generateUrl2('/api/im/ack_msg_read', false);
  },
  postImSendMsg() {
    return generateUrl2('/api/im/send_msg', false);
  },
  getImMsgList() {
    return generateUrl2('/api/im/msg_list', false);
  },
  getImMsgInfo() {
    return generateUrl2('/api/im/msg_info', false);
  },
  getImAskTemplate() {
    return generateUrl2('/api/im/im_template_list', false);
  },
  getImConversationInfo() {
    return generateUrl2('/api/im/conversation_info', false);
  },
  getImConversationList() {
    return generateUrl2('/api/im/conversation_list', false);
  },
  postImChangeConversationState() {
    return generateUrl2('/api/im/change_conversation_state', false);
  },
  getImSearchConversationList() {
    return generateUrl2('/api/im/search_conversation_list', false);
  },
  getImSearchMsgList() {
    return generateUrl2('/api/im/search_msg_list', false);
  },
  postImTransMsgBody() {
    return generateUrl2('/api/im/trans_msg_body', false);
  },
  postImTransFail() {
    return generateUrl2('/api/im/report_trans_fail', false);
  },
  postImRetryTranslate() {
    return generateUrl2('/api/im/retry_translate', false);
  },
  postImVistorMerge() {
    return generateUrl2('/api/im/merge_vistor_msg', false);
  },
  getSightseerMsg() {
    return generateUrl2('/api/im/unread_msg_count', false);
  },
  postSetLanguage() {
    return generateUrl2('/api/im/set_trans_language', false);
  },
  getFaqList() {
    return generateUrl2('/api/faq/list', false);
  },
  postFaqReview() {
    return generateUrl2('/api/faq/review', false);
  },
  postImClickMsg() {
    return generateUrl2('/api/im/click_msg', false);
  },
};
