<template>
  <div class="w-select-tag" :class="{'is-closable': closable}">
    <span class="w-select-tag__label">{{ label }}</span>
    <w-button v-if="closable"
      class="w-select-tag__close"
      size="xs"
      icon name="icon_close" :icon-size="12"
      @click.stop="onClose" />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    closable: Boolean,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
