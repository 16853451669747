var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"reference",class:[
    'w-select',
    `w-select--${_vm.size}`,
    {
      'is-multiple': _vm.multiple,
      'is-searchable': _vm.searchable,
      'is-disabled': _vm.selectDisabled,
      'is-reating': _vm.selectReating,
      'is-dropdown-hover': _vm.hover || _vm.showSubDropdown,
      'w-select--selected': _vm.isSelected,
      'w-select--active': _vm.active,
      [`w-select--${_vm.theme}`]: _vm.theme !== 'light',
    },
  ],attrs:{"tabindex":"0"}},[_c('div',{staticClass:"w-select__wrap",class:{'is-placeholder': !_vm.isSelected},on:{"click":_vm.handleToggleActive}},[_c('div',{staticClass:"w-select__inner"},[(!_vm.isSelected && !_vm.searchVal)?_c('span',{staticClass:"w-select__placeholder"},[_vm._v(_vm._s(_vm.placeholder))]):(_vm.selected)?[(_vm.$scopedSlots.default)?[_vm._t("default",null,{"selected":_vm.selected})]:(_vm.multiple)?[_vm._l((_vm.selected.slice(0, _vm.multipleCollapseCount)),function(item){return _c('SelectTag',{key:item.key,ref:"tag",refInFor:true,attrs:{"label":item.label,"closable":""},on:{"close":function($event){return _vm.handleSelect(item)}}})}),(_vm.selected.length > _vm.multipleCollapseCount)?_c('SelectTag',{attrs:{"label":`+${_vm.selected.length - _vm.multipleCollapseCount}`}}):_vm._e()]:(!_vm.searchable)?[_vm._v(_vm._s(_vm.selected.label))]:_vm._e()]:_vm._e(),(_vm.searchable)?_c('input',{ref:"searchInput",staticClass:"w-select__search",style:({ width: `${_vm.searchVal.length * 12 + 4}px` }),domProps:{"value":_vm.searchVal},on:{"input":_vm.handleSearchValChange,"compositionupdate":_vm.handleSearchValChange}}):_vm._e()],2),(!_vm.selectDisabled && _vm.clearable && _vm.isSelected)?_c('w-button',{staticClass:"w-select__clear",attrs:{"type":"gf3","icon":"","name":"icon_home_clearfilter","icon-size":14,"size":"xs"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClear.apply(null, arguments)}},nativeOn:{"mousedown":function($event){$event.preventDefault();}}}):_vm._e(),_c('w-icon',{staticClass:"w-select__icon",attrs:{"name":"icon_arrow_down","size":16}})],1),_c('SelectDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],ref:"popper",staticClass:"w-select-dropdown",class:[
      _vm.multiple ? 'is-multiple' : '',
      _vm.type ? `w-select-dropdown--${_vm.type}` : '',
      { 'theme-dark': _vm.theme === 'dark' }],attrs:{"offset":[0, 8]},nativeOn:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('w-scrollbar',{style:(_vm.style),attrs:{"prevent-bar":_vm.showSubDropdown,"wrap-class":"w-select-dropdown__wrap","auto-height":""}},[(_vm.searchable && _vm.searchVal && !_vm.displayOptions.length)?[_c('div',{staticClass:"w-select-dropdown__empty"},[_vm._t("empty",function(){return [_c('i',{staticClass:"w-select-dropdown__empty-icon"}),_c('span',{staticClass:"font15r-min"},[_vm._v(_vm._s(_vm.$t('lootBar.no_results')))])]})],2)]:[_vm._l((_vm.displayOptions),function(option,index){return [(option.key !== undefined)?_c('div',{key:option.key,staticClass:"w-select-dropdown__item",class:{'is-hover': _vm.showSubDropdown && _vm.hoverOption.key === option.key},on:{"click":function($event){return _vm.handleSelect(option)},"mouseenter":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseenterOption(option, $event)},"mouseleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseoutOption()}}},[(_vm.$scopedSlots.option)?_vm._t("option",null,{"option":option,"selected":_vm.multiple ? _vm.selected?.includes(option)
                : _vm.selected?.key === option.key}):(_vm.multiple)?[_c('w-checkbox',{staticClass:"w-select-dropdown__item-checkbox",attrs:{"value":_vm.value.includes(option.key),"icon-type":"selectboxpng","img":"png"}}),_c('span',{staticClass:"w-select-dropdown__item-label"},[_vm._v(" "+_vm._s(option.label))])]:[_vm._v(" "+_vm._s(option.label)+" "),(option.sub)?_c('i',{staticClass:"w-select__icon"}):_vm._e()]],2):(option.label)?_c('div',{key:option.label,staticClass:"w-select-dropdown__label"},[(_vm.$scopedSlots.label)?_vm._t("label",null,{"option":option}):[_vm._v(_vm._s(option.label))]],2):_c('hr',{key:`hr-${index}`,staticClass:"w-select-dropdown__hr"})]})]],2)],1),(_vm.showSubDropdown)?_c('SelectSubDropdown',{ref:"subPopper",attrs:{"options":_vm.hoverOption.sub,"reference":_vm.hoverEl},on:{"select":_vm.handleSelect},nativeOn:{"mouseenter":function($event){_vm.hoverSubDropdown = true},"mouseleave":function($event){_vm.hoverSubDropdown = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }