<template>
  <div
    :class="[
      'w-input',
      `w-input--${size}`,
      {
        'is-disabled': inputDisabled,
        'is-reating': inputReating,
        'is-error': inputError,
        'is-focus': injectFocus,
        'w-input--prefix': $slots.prefix,
        'w-input--suffix': $slots.suffix,
        'w-input--button': $slots.button,
      },
    ]">
    <div v-if="$slots.prefix" class="w-input__prefix">
      <slot name="prefix"></slot>
    </div>
    <input
      ref="input"
      :type="type"
      :placeholder="placeholder"
      :disabled="inputDisabled || inputReating"
      :readonly="readonly"
      :value="value"
      :autocomplete="autocomplete === 'off' ? 'new-password' : autocomplete"
      @paste="handlePaste"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @compositionstart="handleCompositionStart"
      @compositionend="handleCompositionEnd" />
    <w-icon
      v-if="!inputDisabled && clearable && !!value"
      class="w-input__clear"
      name="icon_home_clearfilter"
      :size="12"
      @click="handleClear"
      @mousedown.native.prevent />
    <div
      v-if="showLimit"
      class="w-input__count"
      :class="[countError ? 'w-input__count--overflow' : '']">
      <span>{{ curLength }}</span>/{{ maxLength }}
    </div>
    <div class="w-input__suffix"
      @mousedown.prevent>
      <slot name="suffix"></slot>
    </div>
    <div class="w-input__button">
      <slot name="button"></slot>
    </div>
    <div class="w-input__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { gbLen } from '../../utils';

export default {
  name: 'w-input',
  inject: {
    form: {
      default: {},
    },
  },
  props: {
    value: [String, Number],
    disabled: Boolean,
    error: Boolean,
    readonly: Boolean,
    reating: Boolean,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    clearable: Boolean,
    showLimit: Boolean,
    maxLength: Number,
    size: {
      type: String,
      default: 'm',
    },
    formatter: Function,
    autocomplete: String,
  },
  data() {
    return {
      focusing: false,
      curLength: gbLen(this.value),
      countError: false,

      isPaste: false,
      injectFocus: false,
      isCompotioning: false,
    };
  },
  computed: {
    inputDisabled() {
      return this.disabled || this.form.disabled;
    },
    inputReating() {
      return this.reating || this.form.reating;
    },
    inputError() {
      return this.error;
    },
    // 针对ios12键盘不回弹兼容
    isOldiOS() {
      const u = navigator.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ?? false;
      if (u) {
        const osVersion = navigator.userAgent.match(/iPhone\sOS\s([\d_]+)/i) || [];
        const osArr = osVersion.length >= 1 ? osVersion[1].split('_') : [];
        const oldOS = osArr.length >= 2 && (osArr[0] < 13);
        return oldOS;
      }
      return false;
    },
  },
  watch: {
    value(val) {
      const _val = val;
      this.$refs.input.value = _val;
      this.curLength = gbLen(_val);
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    handleInputFunction(e) {
      this.countError = false;
      const _value = this.value; // 缓存之前的值
      const { value } = e.target;
      let val = value;
      if (this.formatter) {
        val = this.formatter(value, _value);
      }
      if (this.maxLength) {
        const len = gbLen(val);
        if (len > this.maxLength) {
          if (this.isPaste) {
            val = val.slice(0, this.maxLength);
            this.isPaste = false;
          } else {
            val = _value;
          }
          this.countError = true;
        }
      }
      e.target.value = val;
      this.$emit('input', val);
    },
    handleInput(e) {
      if (this.isCompotioning) {
        setTimeout(() => {
          this.handleInputFunction(e);
        }, 100);
      } else {
        this.handleInputFunction(e);
      }
    },
    handlePaste() {
      this.isPaste = true;
    },
    handleFocus(e) {
      this.focusing = true;
      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.focusing = false;
      this.$emit('blur', e);
      if (this.isOldiOS) {
        setTimeout(() => {
          this.handleBlurInOldiOS();
        });
      }
    },
    handleClear() {
      this.$emit('input', '');
      this.$emit('clear');
    },
    handleCompositionStart() {
      this.isCompotioning = true;
    },
    handleCompositionEnd() {
      this.isCompotioning = false;
    },
    // 针对ios12键盘不回弹兼容
    handleBlurInOldiOS() {
      const timer = setInterval(() => {
        window.scrollTo(0, 0);
        clearInterval(timer);
      }, 1);
    },
  },
};
</script>
