<template>
  <div class="w-count" :class="[typeCss, sizeCss, {' inFocus': isFocus }]">
    <w-button class="w-count-btn"
      :disabled="offMinus"
      icon plain type="bg1"
      name="icon_minus" :icon-size="iconSize"
      @touchstart.native="onMousedown"
      @mousedown.native="onMousedown"
      @click="onMinus" />
    <div class="w-count-input">
      <label>{{ count }}</label>
      <input
        ref="input"
        v-model.number="count"
        type="number"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        @keydown="onKeydown"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
        @blur="onBlur" />
        <span v-if="unit">{{ unit }}</span>
    </div>
    <w-button class="w-count-btn"
      :disabled="offPlus"
      icon plain type="bg1"
      name="icon_plus" :icon-size="iconSize"
      @touchstart.native="onMousedown"
      @mousedown.native="onMousedown"
      @click="onPlus" />
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    size: {
      type: String,
      default: '',
      validator(val) {
        return ['xs', 's', ''].includes(val);
      },
    },
    placeholderNumber: {
      type: Number,
      default: 0,
    },
    iconSize: {
      type: Number,
      default: 12,
    },
    num: {
      type: Number,
      default: 1,
    },
    allowingEmptyInput: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    realMin: {
      type: Number,
      default: 0,
    },
    max: Number,
    isCeil: {
      type: Boolean,
      default: false,
    },
    hasOutsideClick: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    unit: String,
  },
  data() {
    return {
      isFocus: false,
      count: this.num,
      oldChangeCount: 0,
      changeTimer: null,
      /** 这个变量主要用来标记一下，blur、change中不处理相应事件
       * 背景原因：
       * 1、button是用了click,这个事件比blur、change要慢
       * 2、blur事件会有值的处理
       * 解决文案：
       * 1、添加mousedown或者touchstart事件，这些事件比blur、change快
       * 2、上面标记事件后，blur、change中不处理
       * ps：为什么不直接改成mousedown或者touchstart是之前有用，其次要区分一下事件使用，尽量不影响原来的吧
       */
      disableBlur: false,
    };
  },
  computed: {
    _realMin() {
      return Math.max(this.realMin, this.min);
    },
    offMinus() {
      // 若为空，则判断是否有占位值
      if (this.count === '') {
        return this.placeholderNumber <= this._realMin;
      }
      return this.count <= this._realMin;
    },
    offPlus() {
      // 若为空，则判断是否有占位值
      if (this.count === '') {
        return this.max !== undefined && this.placeholderNumber >= this.max;
      }
      return this.max !== undefined && this.count >= this.max;
    },
    typeCss() {
      return this.type ? `w-count-${this.type}` : '';
    },
    sizeCss() {
      return this.size ? `w-count--${this.size}` : '';
    },
    placeholder() {
      if (this.placeholderNumber > 0) {
        return this.placeholderNumber;
      }
      return '';
    },
  },
  watch: {
    num: {
      handler() {
        if (!this.num && this.allowingEmptyInput) {
          this.count = '';
        } else {
          this.count = this.num;
        }
      },
      immediate: true,
    },
    count() {
      const num = this.count || this._realMin;
      this.$emit('input', num);
    },
  },
  methods: {
    onKeydown(event) {
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    /**
     * fix: change和blur多次触发change事件
     * 1、金币自定义业务中允许为空，blur不会触发change，需要blur补个change事件。
     * 2、若change和blur同时触发时，需要延迟触发change事件
     */
    onEmitChange(count) {
      if (this.oldChangeCount && count === this.oldChangeCount) {
        return;
      }
      this.oldChangeCount = count;
      this.$emit('change', count);
    },
    onInput() {
      // 输入允许为空时，不检查最小值
      if (this.allowingEmptyInput) {
        if (this.count > this.max) {
          this.count = this.max;
        }
        return;
      }
      // 原input逻辑不处理吧
      const count = parseInt(this.count, 10);
      if (Number.isNaN(count) || count < this.min) {
        this.count = this.min;
        this.$emit('change', this.count);
        return;
      }
      if (count > this.max) {
        this.count = this.max;
        this.$emit('change', this.count);
        return;
      }
      this.count = count;
    },
    onChange() {
      // 避免原change事件引起的count值变化了
      if (this.count === '' && this.disableBlur) {
        return;
      }
      const { count } = this;
      if (count % this.step) {
        this.$emit('modify');
      }
      this.count = this.valid(this.count);
      // this.$emit('change', this.count);
      this.onEmitChange(this.count);
    },
    onFocus() {
      this.isFocus = true;
      this.$emit('log', { type: 'focus' });
    },
    onBlur() {
      this.isFocus = false;
      this.$emit('log', { type: 'blur' });
      // +-时禁止blur事件的抛出
      if (this.disableBlur) {
        this.disableBlur = false;
        return;
      }
      if (this.count === '' && this.placeholderNumber) {
        this.count = this.placeholderNumber;
      }
      this.count = this.valid(this.count);
      this.onEmitChange(this.count);
    },
    valid(num) {
      const min = this._realMin;
      if (Number.isNaN(num)) {
        return min;
      }
      /** 当前输入值不能被step取整，则进行取整处理
       * 1.默认是向下取整
       * 2.可以传参进来，向上：ceil
       * 只能支持向上或者向下取整
       */
      if (num % this.step) {
        num = Math[this.isCeil ? 'ceil' : 'floor'](num / this.step) * this.step;
      }
      if (num <= min) {
        return min;
      }
      if (this.max && num > this.max) {
        return this.max;
      }
      return num;
    },
    onMinus() {
      if (this.offMinus) {
        return;
      }
      const count = this.count || this.placeholderNumber;
      this.count = this.valid(count - this.step);
      this.onEmitChange(this.count);
      this.$emit('log', { type: 'minus' });
    },
    onMousedown() {
      this.disableBlur = true;
    },
    onPlus() {
      if (this.offPlus) {
        return;
      }
      const count = this.count || this.placeholderNumber;
      this.count = this.valid(count + this.step);
      this.onEmitChange(this.count);
      this.$emit('log', { type: 'plus' });
    },
    reset() {
      this.count = this._realMin;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit('outside');
      }
    },
  },
  mounted() {
    if (this.hasOutsideClick) {
      document.addEventListener('click', this.handleClickOutside);
    }
    if (this.autoFocus && this.$refs.input) {
      this.$refs.input.focus();
    }
  },
  beforeDestroy() {
    if (this.hasOutsideClick) {
      document.removeEventListener('click', this.handleClickOutside);
    }
  },
};
</script>
<style lang="less" scoped>
.w-count {
  display: flex;
  max-width: 200px;
  height: 40px;
  &.w-count-primary1 {
    width: 100%;
    max-width: unset;
    &>div {
      height: 100%;
      cursor: pointer;
      &.w-count-input {
        flex: 1;
      }
      &.disabled {
        pointer-events: auto;
      }
    }
  }
  &-btn {
    width: 40px!important;
    height: 40px!important;
  }
  &-input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    margin: 0 4px;
    position: relative;
    text-align: center;
    min-width: 80px;
    width: auto;
    background-color: var(--bg-bg2);
    border: 1px solid var(--bg-bg2);
    border-radius: 4px;
    &:hover {
      border-color: var(--el-bc0);
    }

    &.is-focus,
    &:focus-within {
      border-color: var(--el-bc0);
      background-color: var(--bg-bg1);
    }

    input {
      border: 0;
      background: transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 100%;
      text-align: center;
      font-size: 16px;
    }
    label {
      visibility: hidden;
    }

    span{
      position: absolute;
      display: flex;
      align-items: center;
      right: 6px;
      font-size: 14px;
      color: var(--el-gf3);
      margin-top: 1px;
    }
  }

  &--s {
    height: 32px;
  }
  &--s & {
    &-btn {
      width: 32px!important;
      height: 32px!important;
    }
    &-input {
      min-width: 48px;
      input {
        font-size: 15px;
      }
    }
  }

  &--xs {
    height: 24px;
  }
  &--xs & {
    &-btn {
      width: 24px!important;
      height: 24px!important;
    }
    &-input {
      min-width: 48px;
      input {
        font-size: 12px;
      }
    }
  }
}
</style>
