export const ConversationType = {
  P2P: 1,
  GROUP: 2,
};

export const MsgType = {
  TEXT: 1,
  IMG: 2,
  ORDER: 3,
  TEMPLATE: 4,
  WELCOME: 5,
  CARD: 6,
  TEXT_BBCODE: 7,
  PROBLEM: 8,
};

export const TranslateState = {
  RETRY_TRANSLATE: -1,
  NOT_TRANSLATED: 0,
  SUCCESS: 1,
  REPORTED: 3,
};

export const TranslateMethod = {
  Default: 1,
  Google: 2,
};

export const RouteHelperImParams = {
  'Topup': 'topup',
  'Index': 'index',
  'Coin': 'game-coins',
  'Gameitem': 'game-items',
  'Account.MyBalance.Deposit': 'account_balance_deposit',
  'Account.Buy': 'account_buy',
  'Account.Coupon': 'account_coupon',
  'Payment': 'payment',
  'Detail': 'index_cdkey',
};
