export const TextType = {
  zh: 'zh',
  zh_Hant: 'zh_Hant',
  en: 'en',
  ja: 'ja',
  id: 'id', // 印尼
  ms: 'ms', // 马来西亚
  fil: 'fil', // 菲律宾
  vi: 'vi', // 越南
  th: 'th', // 泰国
  fr: 'fr', // 法语
  it: 'it', // 意大利语
  de: 'de', // 德语
  es: 'es', // 西班牙语
  ru: 'ru', // 俄语
};

export const AlternativeLanguages = [
  'zh-Hant', 'ja', 'id', 'ms', 'fr', 'it', 'de', 'es', 'ru',
];

export const HreflangType = {
  'zh-Hant': TextType.zh_Hant,
  en: TextType.en,
  ja: TextType.ja,
  id: TextType.id,
  ms: TextType.ms,
  vi: TextType.vi,
  fr: TextType.fr,
  it: TextType.it,
  de: TextType.de,
  es: TextType.es,
  ru: TextType.ru,
};
export const TextTypeHreflangMap = {
  [TextType.zh_Hant]: 'zh-Hant',
  [TextType.ja]: 'ja',
  [TextType.id]: 'id',
  [TextType.ms]: 'ms',
  [TextType.vi]: 'vi',
  [TextType.fr]: 'fr',
  [TextType.it]: 'it',
  [TextType.de]: 'de',
  [TextType.es]: 'es',
  [TextType.ru]: 'ru',
};

export const LanguageLabel = {
  [TextType.en]: 'EN',
  [TextType.ja]: '日本語',
  [TextType.zh_Hant]: '中文(繁體)',
  [TextType.id]: 'Bahasa Indonesia',
  [TextType.ms]: 'Bahasa Melayu',
  [TextType.fr]: 'Français',
  [TextType.it]: 'Italiano',
  [TextType.de]: 'Deutsch',
  [TextType.es]: 'Español',
  [TextType.ru]: 'Русский',
};
export const LanguageShortLabel = {
  [TextType.en]: 'EN',
  [TextType.ja]: 'JA',
  [TextType.zh_Hant]: 'ZH',
  [TextType.id]: 'ID',
  [TextType.ms]: 'MS',
  [TextType.fr]: 'FR',
  [TextType.it]: 'IT',
  [TextType.de]: 'DE',
  [TextType.es]: 'ES',
  [TextType.ru]: 'RU',
};

export const FeedbackLanguageMap = {
  [TextType.zh]: 'zh-Hant',
  [TextType.zh_Hant]: 'zh-Hant',
  [TextType.en]: 'en-US',
  [TextType.ja]: 'ja-JP',
  [TextType.id]: 'id',
  [TextType.ms]: 'ms',
};

export const LianlianpayLanguageMap = {
  [TextType.zh]: 'zh-TW',
  [TextType.zh_Hant]: 'zh-TW',
  [TextType.en]: 'en-US',
  [TextType.ja]: 'ja-JP',
};

export const TrustpilotLanguageMap = {
  [TextType.en]: 'en-US',
  [TextType.ja]: 'ja-JP',
  [TextType.fr]: 'fr-FR',
  [TextType.de]: 'de-DE',
};

export const BlogUrlEnumLanguage = {
  [TextType.en]: '/blog/',
  [TextType.zh_Hant]: '/blog/zh-Hant/',
  [TextType.ja]: '/blog/ja/',
  [TextType.ms]: '/blog/ms/',
  [TextType.id]: '/blog/id/',
  [TextType.fr]: '/blog/fr/',
  [TextType.de]: '/blog/de/',
};

export const DialogState = {
  confirm: 1,
  cancel: 2,
  close: 3,
};

export const VerifyStateEnums = {
  verify: 'Verify',
  declined: 'Declined',
  pending: 'Pending',
  verified: 'Verified',
};

export const VerifyEmailStatus = {
  verified: '0',
  expired: '1',
};

export const AccountTypeEnum = {
  facebook: 1,
  twitter: 2,
  line: 3,
  apple: 5,
  google: 6,
  password: 7,
  tiktok: 8,
  vk: 9,
};

export const AccountTypeNameEnum = {
  [AccountTypeEnum.facebook]: 'Facebook',
  [AccountTypeEnum.twitter]: 'Twitter',
  [AccountTypeEnum.line]: 'Line',
  [AccountTypeEnum.apple]: 'Apple',
  [AccountTypeEnum.google]: 'Google',
  [AccountTypeEnum.password]: 'Email',
  [AccountTypeEnum.tiktok]: 'Tiktok',
  [AccountTypeEnum.vk]: 'VK',
};

export const ProfileGuideEnum = {
  signin: 'signin',
  email: 'email',
  emailSupport: 'emailSupport',
  bindGoogle: 'bindGoogle',
  bindFacebook: 'bindFacebook',
  bindTwitter: 'bindTwitter',
  bindLine: 'bindLine',
  bindApple: 'bindApple',
  bindTiktok: 'bindTiktok',
  bindVk: 'bindVk',
  bindKnivesout: 'bindKnivesout',
  addBookmarkSafari: 'addBookmarkSafari',
  openInBrowserTiktok: 'openInBrowserTiktok',
  signinTiktok: 'tiktok',
};

export const ProfileErrorGuideMap = {
  unauthorized: ProfileGuideEnum.signin,
  'Email Not Bound': ProfileGuideEnum.email,
  'Partner Role Not Bound': ProfileGuideEnum.bindKnivesout,
};

export const FpType = {
  feedback: 'feedback',
  community: 'community',
  retrieve: 'retrieve',
  roleRetrieve: 'roleRetrieve',
  sellerCenter: 'sellerCenter',
  chat: 'chat',
  utilsGenshinImpact: 'utilsGenshinImpact',
  buyerInfo: 'buyerInfo',
  avatar: 'avatar',
  promote: 'promote',
  sellerManagement: 'sellerManagement',
  commentUpload: 'commentUpload',
};
export const FpFileType = {
  [FpType.community]: 1,
  [FpType.retrieve]: 2,
  [FpType.roleRetrieve]: 3,
  [FpType.sellerCenter]: 5,
  [FpType.chat]: 7,
  [FpType.utilsGenshinImpact]: 19,
  [FpType.buyerInfo]: 20,
  [FpType.avatar]: 22,
  [FpType.promote]: 23,
  [FpType.sellerManagement]: 24,
  [FpType.commentUpload]: 25,
};

export const CountryCodeCurrencyCodeMap = {
  US: 'USD', // 美元
  JP: 'JPY', // 日元
  TW: 'TWD', // 新台币
  CN: 'TWD', // 新台币
  ID: 'IDR', // 印尼盾
  MY: 'MYR', // 马来西亚令吉
  PH: 'PHP', // 菲律宾比索
  VN: 'VND', // 越南盾
  TH: 'THB', // 泰铢
  SG: 'SGD', // 新加坡元
  RU: 'RUB', // 俄罗斯卢布
  CA: 'CAD', // 加拿大元
  AU: 'AUD', // 澳大利亚元
  IE: 'EUR', // 欧元：爱尔兰
  FR: 'EUR', // 欧元：法国
  DE: 'EUR', // 欧元：德国
  AT: 'EUR', // 欧元：奥地利
  BE: 'EUR', // 欧元：比利时
  CY: 'EUR', // 欧元：塞浦路斯
  EE: 'EUR', // 欧元：爱沙尼亚
  FI: 'EUR', // 欧元：芬兰
  GR: 'EUR', // 欧元：希腊
  IT: 'EUR', // 欧元：意大利
  LV: 'EUR', // 欧元：拉托维亚
  LT: 'EUR', // 欧元：立陶宛
  LU: 'EUR', // 欧元：卢森堡
  MT: 'EUR', // 欧元：马耳他
  PT: 'EUR', // 欧元：葡萄牙
  ES: 'EUR', // 欧元：西班牙
  SK: 'EUR', // 欧元：斯洛伐克
  SI: 'EUR', // 欧元：斯洛文尼亚
  HR: 'EUR', // 欧元：克罗地亚
  NL: 'EUR', // 欧元：荷兰
  CH: 'EUR', // 欧元：瑞士
  DK: 'EUR', // 欧元：丹麦
  SE: 'EUR', // 欧元：瑞典
  NO: 'EUR', // 欧元：挪威
  IS: 'EUR', // 欧元：冰岛
  CZ: 'EUR', // 欧元：捷克
  GB: 'GBP', // 英镑：英国
};
export const LanguageCurrencyCodeMap = {
  [TextType.en]: 'USD', // 美元
  [TextType.ja]: 'JPY', // 日元
  [TextType.zh_Hant]: 'TWD', // 新台币
  [TextType.id]: 'IDR', // 印尼盾
  [TextType.ms]: 'MYR', // 马来西亚令吉
  [TextType.fil]: 'PHP', // 菲律宾比索
  [TextType.vi]: 'VND', // 越南盾
  [TextType.th]: 'THB', // 泰铢
  [TextType.it]: 'EUR', // 欧元
  [TextType.fr]: 'EUR', // 欧元
  [TextType.de]: 'EUR', // 欧元
  [TextType.ru]: 'RUB', // 俄罗斯卢布
};

export const CountryCodeLanguageMap = {
  US: TextType.en,
  GB: TextType.en,
  JP: TextType.ja,
  ID: TextType.id,
  MY: TextType.ms,
  FR: TextType.fr,
  IT: TextType.it,
  SM: TextType.it, // 圣马力诺
  CH: TextType.it, // 瑞士
  VA: TextType.it, // 梵蒂冈
  MT: TextType.it, // 马耳他骑士团
  DE: TextType.de,
  RU: TextType.ru,
  AR: TextType.es, // 阿根廷
  BO: TextType.es, // 玻利维亚
  CL: TextType.es, // 智利
  CO: TextType.es, // 哥伦比亚
  CR: TextType.es, // 哥斯达黎加
  CU: TextType.es, // 古巴
  DO: TextType.es, // 多米尼加
  EC: TextType.es, // 厄瓜多尔
  SV: TextType.es, // 萨尔瓦多
  GQ: TextType.es, // 赤道几内亚
  GT: TextType.es, // 危地马拉
  HN: TextType.es, // 洪都拉斯
  MX: TextType.es, // 墨西哥
  NI: TextType.es, // 尼加拉瓜
  PA: TextType.es, // 巴拿马
  PY: TextType.es, // 巴拉圭
  PE: TextType.es, // 秘鲁
  PR: TextType.es, // 波多黎各
  ES: TextType.es, // 西班牙
  UY: TextType.es, // 乌拉圭
  VE: TextType.es, // 委内瑞拉
};

// 欧洲国家/地区+英美
export const EuropeCountryEnum = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'US'];

// 支付页显示反馈支付方式按钮的国家/地区
export const FeedbackCountryEnum = ['JP', 'US', 'GB', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];

export const CookiePolicyCountryEnum = [
  'AT', // 奥地利
  'BE', // 比利时
  'BG', // 保加利亚
  'HR', // 克罗地亚
  'CY', // 赛普勒斯
  'CZ', // 捷克
  'DK', // 丹麦
  'EE', // 爱沙尼亚
  'FI', // 芬兰
  'FR', // 法国
  'DE', // 德国
  'GR', // 希腊
  'HU', // 匈牙利
  'IE', // 爱尔兰
  'IT', // 意大利
  'LV', // 拉脱维亚
  'LT', // 立陶宛
  'LU', // 卢森堡
  'MT', // 马耳他
  'NL', // 荷兰
  'PL', // 波兰
  'PT', // 葡萄牙
  'RO', // 罗马尼亚
  'SK', // 斯洛伐克
  'SI', // 斯洛文尼亚
  'ES', // 西班牙
  'SE', // 瑞典
  'GB', // 英国
  'US', // 美国
  'HK',
];

export const BlogUrlEnum = 'https://lootbar.gg';
