<template>
  <w-modal
    class="w-dialog__wrapper"
    :class="{[`w-dialog--${size}`]: size}"
    :show="show"
    :close-on-click-modal="false"
    @close="handleClose">
    <div class="w-dialog__outer"
      :style="{
        maxHeight: `${maxHeight}px`,
        minHeight: minHeight,
      }">
      <div class="w-dialog">
        <w-button v-if="closeBtn"
          class="w-dialog__close"
          flat icon name="icon_close"
          v-bind="closeBtnConf"
          :icon-size="20"
          @click="handleClose" />
        <div
          v-if="title || $slots.headerSlot"
          class="w-dialog__header"
          :class="{'w-dialog__header--shadow': !scrollState.start}">
          <div v-if="titleIcon" class="w-dialog__header-icon">
            <w-icon :name="titleIcon" :size="24" /></div>
          <div
            class="w-dialog__header-title"
            :class="sizeStyle.titleClassName">{{ title }}</div>
          <slot name="headerSlot"></slot>
        </div>

        <w-scrollbar ref="scrollbar" class="w-dialog__content-wrap"
          @update="updateScroll" @scroll="updateScroll">
          <div class="font15r w-dialog__content">
            <div v-if="content" class="w-dialog__content-inner"
              :class="{
                'font16b no-title': !title && !$slots.headerSlot,
                'no-footer': !withBtn,
              }"
              v-html="content"></div>
            <slot v-else></slot>
          </div>
        </w-scrollbar>

        <div v-if="withBtn" class="w-dialog__actions" :class="{
          [`w-dialog__actions--${actionsDirection}`]: actionsDirection,
          [sizeStyle.actionsClassName]: sizeStyle.actionsClassName,
          'w-dialog__actions--shadow': !scrollState.end,
        }">
          <div v-for="(button, index) in buttons" :key="index"
            class="w-dialog__actions-item">
            <w-button
              :class="[sizeStyle.buttonCancelClassName, button.className]"
              :loading="buttonLoading"
              v-bind="button.config"
              @click="handleExtraBtnClick(button)">{{ button.text }}
            </w-button>
          </div>
          <div v-if="cancelBtn" class="w-dialog__actions-item">
            <w-button
              :class="sizeStyle.buttonCancelClassName"
              :loading="buttonLoading"
              v-bind="cancelBtnConf"
              @click="handleCancel">{{ formatCancelText }}
            </w-button>
          </div>
          <div v-if="confirmBtn" class="w-dialog__actions-item">
            <w-button
              :class="sizeStyle.buttonConfirmClassName"
              :loading="buttonLoading"
              v-bind="confirmBtnConf"
              @click="handleConfirm">{{ formatConfirmText }}
            </w-button>
          </div>
        </div>
      </div>
    </div>
  </w-modal>
</template>

<script>
import { scrollState } from '@/utils/dom';

const SizeEnum = {
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
};

const SizeStyleMap = {
  [SizeEnum.S]: {
    titleFontSize: 20,
    buttonFontSize: 15,
  },
  [SizeEnum.M]: {
    titleFontSize: 20,
    buttonFontSize: 15,
  },
  [SizeEnum.L]: {
    titleFontSize: 24,
    buttonFontSize: 16,
  },
  [SizeEnum.XL]: {
    titleFontSize: 24,
    buttonFontSize: 16,
  },
};

export default {
  props: {
    show: Boolean,
    title: String,
    titleIcon: String,
    content: String,
    maxHeight: Number,
    minHeight: String,
    size: {
      type: String,
      default: SizeEnum.S,
      validator(val) {
        return Object.values(SizeEnum).includes(val);
      },
    },
    actionsDirection: {
      type: String,
      default: 'row',
      validator(val) {
        return ['row', 'column'].includes(val);
      },
    },
    closeBtn: {
      type: [Boolean, Object],
      default: true,
    },
    cancelBtn: {
      type: [Boolean, Object],
      default: true,
    },
    confirmBtn: {
      type: [Boolean, Object],
      default: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default() { return []; },
    },
    loading: Boolean,
  },
  data() {
    return {
      scrollState: { start: true, end: true },
      innerLoading: false,
    };
  },
  computed: {
    buttonLoading() {
      return this.loading || this.innerLoading;
    },
    sizeStyle() {
      const size = SizeStyleMap[this.size];
      return {
        titleClassName: `font${size.titleFontSize}b`,
        actionsClassName: `font${size.buttonFontSize}r`,
        buttonCancelClassName: `font${size.buttonFontSize}r`,
        buttonConfirmClassName: `font${size.buttonFontSize}b`,
      };
    },
    formatCancelText() {
      return this.cancelText || this.$t('global.cancel');
    },
    formatConfirmText() {
      return this.confirmText || this.$t('global.confirm');
    },
    withBtn() {
      return this.confirmBtn || this.cancelBtn || this.$slots.action;
    },
    confirmBtnConf() {
      if (!this.confirmBtn) return {};
      if (typeof this.confirmBtn === 'object') return this.confirmBtn;
      return { type: 'primary' };
    },
    cancelBtnConf() {
      if (!this.cancelBtn) return {};
      if (typeof this.cancelBtn === 'object') return this.cancelBtn;
      return { plain: true };
    },
    closeBtnConf() {
      if (!this.closeBtn) return {};
      if (typeof this.closeBtn === 'object') return this.closeBtn;
      return { type: 'bg1', transparent: true };
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    updateScroll() {
      const { scrollbar: { wrap: $scrollbar } } = this.$refs;
      const state = scrollState($scrollbar).y;
      this.scrollState = {
        start: state.start,
        end: state.end,
      };
    },
    async handleConfirm(evt) {
      this.$emit('confirm', evt);
    },
    async handleExtraBtnClick(button) {
      if (button.handleClick) {
        this.innerLoading = true;
        try {
          await button.handleClick();
        } catch (err) {
          this.innerLoading = false;
          return;
        }
        this.innerLoading = false;
      }
      this.$emit('close');
    },
    handleCancel(evt) {
      this.$emit('cancel', evt);
    },
  },
};
</script>

<style lang="less" scoped>
.w-dialog {
  position: relative;
  &__header {
    display: flex;
    align-items: center;
    transition: box-shadow 0.1s ease-in-out;
    &-icon {
      flex: 0 0 auto;
      margin-right: 8px;
      line-height: 0;
    }
    &--shadow {
      box-shadow: var(--shadow-1);
    }
  }
  &__actions {
    &--shadow {
      box-shadow: var(--shadow-1);
    }
  }
  &__outer {
    padding: 80px 0;
  }
  &__content {
    &-inner {
      padding: 0 20px;
      &.no-title {
        padding-top: 24px;
        color: var(--el-gf1);
      }
      &.no-footer {
        padding-bottom: 20px;
      }
    }
  }
  @size: {
    s: 400px 200px 40px;
    m: 500px 240px 40px;
    l: 680px 320px 48px;
    xl: 860px 420px 48px;
  }
  each(@size, {
    &--@{key} {
      .w-dialog__outer {
        width: extract(@value, 1);
        min-height: extract(@value, 2);
      }
      .w-dialog__actions-item > * {
        height: extract(@value, 3);
        @media (max-width: 750px)  {
          height: 48px;
          font-size: 16px;
        }
      }
    }
  });
}
@media (max-width: 750px) {
  .w-dialog__outer {
    width: 320px !important;
    min-height: 144px !important;
  }
  .w-dialog__header-title {
    font-size: 18px !important;
    line-height: 26px !important;
    text-align: center;
  }
  .w-dialog__content-inner {
    text-align: center;
  }
}
</style>
