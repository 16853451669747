import { generateUrl as generateUrl2 } from './utils';

export default {
  // 获取卖家操作token
  getSellerOperateToken() {
    return generateUrl2('/api/market/seller/get_operate_token', false);
  },
  // 获取在售SKU列表（用于改价）
  getOnSellSkuList() {
    return generateUrl2('/api/market/seller/on_sell_sku_list', false);
  },
  // 获取在售金币列表（用于改价）
  getOnSellCoinList() {
    return generateUrl2('/api/market/seller/on_sell_coin_list', false);
  },
  // 商家更改卖单价格
  changeSellPrice() {
    return generateUrl2('/api/market/seller/change_sell_price', false);
  },
  // 商家更改金币价格
  changeCoinSellPrice() {
    return generateUrl2('/api/market/seller/change_coin_sell_price/create', false);
  },
  // 获取卖单表格
  getOnSellSkuExcel() {
    return generateUrl2('/api/market/seller/on_sell_sku_excel', false);
  },
  // 商家表格更改卖单价格校验
  validateSellPriceExcel() {
    return generateUrl2('/api/market/seller/change_sell_price_excel/validate', false);
  },
  // 商家表格更改卖单价格
  changeSellPriceExcel() {
    return generateUrl2('/api/market/seller/change_sell_price_excel/create', false);
  },
  // 获取表格改价任务状态
  changeSellPriceExcelState() {
    return generateUrl2('/api/market/seller/price_change_task/state', false);
  },
};
