import { generateUrl as generateUrl2 } from './utils';

export default {
  // 云真机：买家申请设备
  postDeviceApply() {
    return generateUrl2('/api/v2/market/bill_order/apply_device');
  },
  // 云真机： 卖家使用设备
  postSellerDeviceApply() {
    return generateUrl2('/api/market/seller/buy_order/device_deliver');
  },
  // 云真机：卖家释放云真机
  postSellerReleaseDevice() {
    return generateUrl2('/api/market/seller/buy_order/device_deliver/release');
  },
  // 云真机：获取所有订单卖家的云服务状态
  getSellerOrderState() {
    return generateUrl2('/api/market/seller/buy_order/state');
  },
};
