<template>
  <div
    ref="reference"
    :class="[
      'w-select',
      `w-select--${size}`,
      {
        'is-multiple': multiple,
        'is-searchable': searchable,
        'is-disabled': selectDisabled,
        'is-reating': selectReating,
        'is-dropdown-hover': hover || showSubDropdown,
        'w-select--selected': isSelected,
        'w-select--active': active,
        [`w-select--${theme}`]: theme !== 'light',
      },
    ]"
    tabindex="0">
    <div class="w-select__wrap"
      :class="{'is-placeholder': !isSelected}"
      @click="handleToggleActive">
      <div class="w-select__inner">
        <span v-if="!isSelected && !searchVal"
          class="w-select__placeholder">{{ placeholder }}</span>
        <template v-else-if="selected">
          <template v-if="$scopedSlots.default">
            <slot :selected="selected"></slot>
          </template>
          <template v-else-if="multiple">
            <SelectTag
              v-for="item in selected.slice(0, multipleCollapseCount)"
              ref="tag"
              :key="item.key"
              :label="item.label"
              closable
              @close="handleSelect(item)" />
            <SelectTag
              v-if="selected.length > multipleCollapseCount"
              :label="`+${selected.length - multipleCollapseCount}`" />
          </template>
          <template v-else-if="!searchable">{{ selected.label }}</template>
        </template>
        <input
          v-if="searchable"
          ref="searchInput"
          :value="searchVal"
          :style="{ width: `${searchVal.length * 12 + 4}px` }"
          class="w-select__search"
          @input="handleSearchValChange"
          @compositionupdate="handleSearchValChange" />
      </div>
      <w-button
        v-if="!selectDisabled && clearable && isSelected"
        class="w-select__clear"
        type="gf3" icon name="icon_home_clearfilter"
        :icon-size="14" size="xs"
        @click.stop="handleClear"
        @mousedown.native.prevent />
      <w-icon class="w-select__icon"
        name="icon_arrow_down" :size="16" />
    </div>
    <SelectDropdown
      v-show="active"
      ref="popper"
      class="w-select-dropdown"
      :class="[
        multiple ? 'is-multiple' : '',
        type ? `w-select-dropdown--${type}` : '',
        { 'theme-dark': theme === 'dark' }]"
      :offset="[0, 8]"
      @mouseover.native="hover = true"
      @mouseleave.native="hover = false">
      <w-scrollbar :style="style"
        :prevent-bar="showSubDropdown"
        wrap-class="w-select-dropdown__wrap"
        auto-height>
        <template v-if="searchable && searchVal && !displayOptions.length">
          <div class="w-select-dropdown__empty">
            <slot name="empty">
              <i class="w-select-dropdown__empty-icon"></i>
              <span class="font15r-min">{{ $t('lootBar.no_results') }}</span>
            </slot>
          </div>
        </template>
        <template v-else>
          <template v-for="(option, index) in displayOptions">
            <div
              v-if="option.key !== undefined"
              :key="option.key"
              class="w-select-dropdown__item"
              :class="{'is-hover': showSubDropdown && hoverOption.key === option.key}"
              @click="handleSelect(option)"
              @mouseenter.self="handleMouseenterOption(option, $event)"
              @mouseleave.self="handleMouseoutOption()">
              <slot
                v-if="$scopedSlots.option"
                name="option"
                :option="option"
                :selected="multiple ? selected?.includes(option)
                  : selected?.key === option.key"></slot>
              <template v-else-if="multiple">
                <w-checkbox
                  class="w-select-dropdown__item-checkbox"
                  :value="value.includes(option.key)"
                  icon-type="selectboxpng"
                  img="png" />
                <span class="w-select-dropdown__item-label">
                  {{ option.label }}</span>
              </template>
              <template v-else>
                {{ option.label }}
                <i v-if="option.sub" class="w-select__icon"></i>
              </template>
            </div>
            <div
              v-else-if="option.label"
              :key="option.label"
              class="w-select-dropdown__label">
              <slot v-if="$scopedSlots.label" name="label" :option="option"></slot>
              <template v-else>{{ option.label }}</template>
            </div>
            <hr v-else :key="`hr-${index}`" class="w-select-dropdown__hr" />
          </template>
        </template>
      </w-scrollbar>
    </SelectDropdown>
    <SelectSubDropdown
      v-if="showSubDropdown"
      ref="subPopper"
      :options="hoverOption.sub"
      :reference="hoverEl"
      @mouseenter.native="hoverSubDropdown = true"
      @mouseleave.native="hoverSubDropdown = false"
      @select="handleSelect" />
  </div>
</template>

<script>
import { addResizeListener, removeResizeListener } from '@/utils/resize-events';

import SelectDropdown from './select-dropdown.vue';
import SelectSubDropdown from './select-sub-dropdown.vue';
import SelectTag from './tag.vue';

export default {
  components: { SelectDropdown, SelectSubDropdown, SelectTag },
  inject: {
    theme: {
      default: 'light',
    },
    form: {
      default: {},
    },
  },
  props: {
    value: [String, Number, Array],
    size: {
      type: String,
      default: 'm',
    },
    clearable: Boolean,
    disabled: Boolean,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    dropdownMaxHeight: Number,
    expandable: { // 弹窗宽度是否可大于输入框
      type: Boolean,
      default: true,
    },
    type: String,
    searchable: Boolean,
    multiple: Boolean,
    multipleCollapseCount: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      active: false,
      popup: null,
      hover: false,

      hoverOption: null,
      hoverEl: null,
      hoverSub: false,
      hoverSubDropdown: false,

      searchVal: '',
    };
  },
  computed: {
    showSubDropdown() {
      return this.hoverSubDropdown || this.hoverSub;
    },
    isSelected() {
      return this.multiple ? this.selected.length > 0 : !!this.selected;
    },
    displayOptions() {
      if (!this.searchable || !this.searchVal) {
        return this.options;
      }
      return this.options.filter((option) => {
        const label = option.label.toLowerCase();
        const searchKey = this.searchVal.toLowerCase();
        return label.indexOf(searchKey) !== -1;
      });
    },
    selected: {
      get() {
        if (this.multiple) {
          if (!this.value) return [];
          return this.value.map((option) => {
            const matched = this.options.find((item) => item.key === option);
            return matched;
          });
        }

        const { value } = this;
        if (value === undefined || value === '' || value === null) return null;
        function match(options) {
          let matched = null;
          for (let i = 0; i < options.length; i += 1) {
            if (options[i].key === value) matched = options[i];
            else if (options[i].sub) matched = match(options[i].sub);
            if (matched) return matched;
          }
          return matched;
        }
        return match(this.options);
      },
      set(val) {
        if (this.multiple) {
          this.$emit('input', val.map((item) => item.key));
        } else {
          this.$emit('input', val?.key, val);
        }
        if (!this.active) {
          this.$emit('blur');
        }
      },
    },
    selectDisabled() {
      return this.disabled || this.form.disabled;
    },
    selectReating() {
      return this.reating || this.form.reating;
    },
    style() {
      if (this.dropdownMaxHeight) {
        return { maxHeight: `${this.dropdownMaxHeight}px` };
      }
      return '';
    },
  },
  watch: {
    selectDisabled(val) {
      if (val) this.active = false;
    },
    active(val) {
      if (val) {
        this.bindEvents();
        this.$emit('focus');
      } else {
        this.searchVal = '';
        this.unbindEvents();
        this.$emit('blur');
      }
    },
    showSubDropdown(val) {
      if (val) {
        this.$nextTick(() => {
          const { subPopper, reference } = this.$refs;
          const key = this.expandable ? 'minWidth' : 'width';
          subPopper[key] = reference.getBoundingClientRect().width;
        });
      } else {
        const { subPopper } = this.$refs;
        subPopper.doDestroy();
      }
    },
  },
  mounted() {
    addResizeListener(this.$refs.popper.$el, this.handleResize);
  },
  beforeDestroy() {
    removeResizeListener(this.$refs.popper.$el, this.handleResize);
    this.unbindEvents();
  },
  methods: {
    handleCloseActive() {
      this.active = false;
    },
    handleToggleActive() {
      if (this.selectDisabled) return;
      if (this.active) {
        this.handleCloseActive();
      } else {
        this.active = true;
        if (this.searchable) this.$refs.searchInput.focus();
        this.$nextTick(() => {
          const { popper, reference } = this.$refs;
          const key = this.expandable ? 'minWidth' : 'width';
          popper[key] = reference.getBoundingClientRect().width;
          popper.updatePopper();
        });
      }
    },
    handleSelect(option) {
      if (option.disabled) return;
      if (this.multiple) {
        let selected = [...this.selected];
        if (selected.some((item) => item.key === option.key)) {
          selected = selected.filter((item) => item.key !== option.key);
        } else {
          selected.unshift(option);
        }
        this.selected = selected;
        if (this.searchable) {
          this.$refs.searchInput.focus();
          this.searchVal = '';
        }
        const { popper } = this.$refs;
        popper.updatePopper();
      } else {
        this.selected = option;
        this.hoverSubDropdown = false;
        this.handleCloseActive();
        if (this.searchable) this.searchVal = option.label;
      }
    },
    handleSearchValChange(e) {
      this.searchVal = e.target.value;
      this.active = true;
      this.$refs.popper.updatePopper();
      if (!this.multiple) this.selected = null;
    },
    handleClear() {
      if (this.multiple) {
        this.selected = [];
      } else {
        this.selected = null;
      }
    },
    handleMouseenterOption(option, event) {
      this.hoverOption = option;
      this.hoverEl = event.target || event.srcElement;
      this.hoverSub = option.sub?.length > 0 ?? false;
    },
    handleMouseoutOption() {
      this.hoverSub = false;
    },
    handleResize() {
      if (!this.active) return;
      const { popper } = this.$refs;
      if (!popper) return;
      popper.updatePopper();
    },
    bindEvents() {
      window.addEventListener('mousedown', this.mousedownEvent, true);
      window.addEventListener('mouseup', this.mouseupEvent, { passive: false });
    },
    unbindEvents() {
      window.removeEventListener('mousedown', this.mousedownEvent, true);
      window.removeEventListener('mouseup', this.mouseupEvent, { passive: false });
    },
    mousedownEvent(e) {
      const { popperElm } = this;
      const { tag = [] } = this.$refs;
      const { target } = e;
      this.closeOnMouseup = !popperElm.includeNode(target)
        && !tag.some((item) => item.$el.includeNode(target));
    },
    mouseupEvent(e) {
      if (this.closeOnMouseup) {
        e.preventDefault();
        this.handleCloseActive();
      }
    },
  },
};
</script>
