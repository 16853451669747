<template>
  <div
    v-if="show || active"
    class="modal-wrapper"
    :class="{'is-active': show && active}"
    :style="{ zIndex: innerZIndex }">
    <div v-if="mask" ref="modal" class="modal-mask"
      :style="{ backgroundColor: color }"></div>
    <slot class="modal"></slot>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import { guid } from '@/utils';
import PopupManager from '../../popup-manager';

let scrollTop = 0;

export default {
  props: {
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    color: String,
    zIndex: Number,
  },
  data() {
    return {
      active: false,
      id: guid(),
      innerZIndex: -1,
      clickEvent: null,
      removed: true,

      timer: null,
    };
  },
  watch: {
    show(val) {
      const self = this;
      if (val) {
        this.append();
        setTimeout(() => {
          this.active = val;
        });
        if (this.timer) {
          clearTimeout(this.timer);
        }
      } else {
        this.unbindEvent();
        this.timer = setTimeout(() => {
          this.active = val;
          self.remove();
        }, 500);
      }
    },
  },
  mounted() {
    if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
    if (this.show) {
      this.$nextTick(() => {
        this.active = this.show;
        this.append();
      });
    }
  },
  beforeDestroy() {
    this.remove();
  },
  methods: {
    bindEvent() {
      if (!this.mask) return;
      const { modal } = this.$refs;
      if (this.closeOnClickModal) {
        const clickEvent = 'click';
        modal.addEventListener(clickEvent, this.clickEvent);
      }
    },
    unbindEvent() {
      if (!this.mask) return;
      const { modal } = this.$refs;
      if (!modal) return;
      if (this.clickEvent) {
        const clickEvent = 'click';
        modal.removeEventListener(clickEvent, this.clickEvent);
      }
    },
    append() {
      if (this.mask && !PopupManager.hasModal()) this.fixBody();
      PopupManager.register(this.id);
      this.innerZIndex = this.zIndex ?? PopupManager.nextZIndex();
      const self = this;
      this.clickEvent = (e) => {
        // e.stopPropagation()
        const t = (e && e.target) || (e && e.srcElement);
        if (t === self.$refs.modal) {
          e.preventDefault();
          self.$emit('close');
        }
      };
      document.body.appendChild(this.$el);
      this.$nextTick(() => {
        this.bindEvent();
      });
      this.removed = false;
    },
    remove() {
      if (this.removed) return;
      PopupManager.unregister(this.id);
      if (this.mask && !PopupManager.hasModal()) this.unfixBody();
      try {
        document.body.removeChild(this.$el);
      } catch (err) { /** */ }
      this.clickEvent = null;
      this.removed = true;
    },
    fixBody() {
      scrollTop = window.scrollY;
      const documentScrollTop = document.documentElement.scrollTop;
      // 在ios safari 下，触发页面底部回弹是打开页面弹窗不能点击问题修复
      document.documentElement.scrollTop = documentScrollTop;

      document.body.style.setProperty('position', 'fixed');
      document.body.style.setProperty('top', `-${scrollTop}px`);
      document.body.style.setProperty('left', '0');
      document.body.style.setProperty('right', '0');
    },
    unfixBody() {
      document.body.style.setProperty('position', '');
      document.body.style.setProperty('top', '');
      document.body.style.setProperty('left', '');
      document.body.style.setProperty('right', '');
      if (!scrollTop) return;
      window.scrollTo(0, scrollTop);
      scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  &-wrapper {
    position: fixed;
    z-index: 99999;
    left: 0; right: 0; top: 0; bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-app-region: no-drag;
    pointer-events: none;
    transition: opacity .2s;
    opacity: 0;
    &.is-active {
      opacity: 1;
    }
    & > :deep(*) {
      pointer-events: auto;
    }
  }
  &-mask {
    background-color: rgba(0, 0, 0, 0.56);
    position: absolute;
    z-index: -1;
    left: 0; right: 0; top: 0; bottom: 0;
  }
  .content-wrap {
    position: relative;
  }
  .close-icon-wrapper {
    font-size: 1.25em;
    position: absolute;
    right: 0.7em;
    top: 0.7em;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px #888;
    i {
      margin-right: 0;
    }
  }
}
</style>
